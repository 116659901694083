/*! variable | Layout */
$max-width: 1920px;
$width: 1280px;


/* color */
$color-black: #000;
$color-white: #fff;
$color-main: #ff354e;


/* font */
$nimbusSans: 'itc-avant-garde-gothic-pro'; //300,500,700
// $itcAvantBold: 'ITCAvantGardeStdBold';
// $itcAvantDemi: 'ITCAvantGardeStdDemi';
// $itcAvantMd: 'ITCAvantGardeStdMd'; //default
$notoSans: 'Noto Sans KR';
