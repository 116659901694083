/*!
 * 폰트 정의.
 * @param  {String} $font-family [폰트 명]
 * @param  {String} $font-size   [폰트 사이즈]
 * @param  {String} $font-weight [폰트 두께]
 */

@mixin font($font-family, $font-size: 14px, $font-weight: null){
  font-family: $font-family, sans-serif;
  font-size: $font-size;
  @if($font-weight!=null) {font-weight: $font-weight;}
}

/*!
 * Float Bug fix.
 */
@mixin clearfix {
  &:after {
    display: block;
    clear: both;
    content:'';
  }
}


@mixin mobile {
  @media screen and (min-width: 750px) and (max-width: 1024px) {
    @content;
  }
}


@function get-vw($font-size) {
  $vw-context: 750 * 0.01 * 1px;
  @return $font-size / $vw-context * 1vw;
}

