@charset "UTF-8";
/*! variable | Layout */
/* color */
/* font */
/*!
 * 폰트 정의.
 * @param  {String} $font-family [폰트 명]
 * @param  {String} $font-size   [폰트 사이즈]
 * @param  {String} $font-weight [폰트 두께]
 */
/*!
 * Float Bug fix.
 */
button {
  cursor: pointer;
}

.blind {
  position: absolute;
  top: -1px;
  left: -1px;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, -1px, -1px);
}

.artist_inner, .section_header, .section_inner {
  max-width: 1160px;
  margin: 0 auto;
}

.section_header .h2_title {
  font-family: "itc-avant-garde-gothic-pro", sans-serif;
  font-size: 102px;
  font-weight: 700;
}

.artist {
  padding-top: 300px;
}

.artist_header {
  position: fixed;
  right: 38px;
  top: 60px;
  z-index: 100;
}

.artist_header .area {
  position: relative;
  width: 60px;
  height: 60px;
  transform: rotate(90deg);
}

.artist_header .area .en {
  position: relative;
  font-family: "itc-avant-garde-gothic-pro", sans-serif;
  font-size: 32px;
  font-weight: 700;
}

.artist_header .area .kr {
  position: relative;
  margin-top: 5px;
  margin-left: 2px;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
  font-weight: 700;
}

.vm .artist_header {
  position: absolute;
}

.vm.board-up .artist_header {
  position: fixed;
  top: 15px;
  transition: top .3s;
}

.vm.board-down .artist_header {
  position: fixed;
  top: -70px;
  transition: top .3s;
}

.vm.board-ready .artist_header {
  position: absolute;
  top: 15px !important;
}

.vm.board-ready.board-down .artist_header {
  top: -70px !important;
}

.artist_keyvisual {
  position: relative;
  margin: 0 auto 264px;
  box-sizing: border-box;
  padding: 0 6.897%;
}

.artist_keyvisual .visual img {
  display: block;
  width: 100%;
}

.artist_keyvisual .en_name {
  position: absolute;
  font-family: "itc-avant-garde-gothic-pro", sans-serif;
  font-size: 380px;
  font-weight: 700;
}

.artist_keyvisual .kr_name {
  position: absolute;
  transform: translateY(50%);
  font-family: "Noto Sans KR", sans-serif;
  font-size: 64px;
  font-weight: 700;
}

.section {
  position: relative;
  padding-left: 20px;
  max-width: 1160px;
  margin: 0 auto;
  box-sizing: border-box;
}

.section.chart {
  margin-top: 65px;
  margin-bottom: 50px;
}

.section.badge {
  max-width: 1920px;
  padding-top: 120px;
  padding-bottom: 150px;
  margin-top: 0px;
  background: #000;
  padding-left: 20px;
}

.section.badge * {
  color: #fff;
}

.section_header {
  position: relative;
}

.section.chart .section_header {
  padding-top: 230px;
}

.section.badge .section_header {
  padding-top: 110px;
}

.section.badge .section_header .sub_title {
  margin-top: 30px;
  padding-left: 5px;
}

.section_header .bg_copy {
  position: absolute;
  top: 0;
  pointer-events: none;
}

.section.graph .section_header .bg_copy {
  top: -245px;
  left: 274px;
  width: 1218px;
  height: 402px;
  background: url("../images/artist/typo_graph.png") 0 0 no-repeat;
}

.section.chart .section_header .bg_copy {
  right: 386px;
  width: 1700px;
  height: 308px;
  background: url("../images/artist/typo_mvchart.png") 0 0 no-repeat;
}

.section.badge .section_header .bg_copy {
  top: -120px;
  left: 144px;
  width: 1337px;
  height: 396px;
  background: url("../images/artist/typo_badge.png") 0 0 no-repeat;
}

.section.today .section_header .bg_copy {
  top: -200px;
  right: 274px;
  width: 727px;
  height: 249px;
  background-size: contain;
  background-image: url("../images/artist/kr-artist-bg-typo-rank.svg");
}

.section_content {
  position: relative;
  margin-top: 70px;
}

.section_content-head:after {
  display: block;
  clear: both;
  content: '';
}

.section_content-head .title {
  float: left;
  width: 100%;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
}

.section_content-head .section_tab {
  float: right;
  width: 50%;
}

.section_content-head .section_tab ul:after {
  display: block;
  clear: both;
  content: '';
}

.section_content-head .section_tab li {
  float: left;
  width: 50%;
  margin-bottom: 6px;
}

.section_content-head .section_tab li:nth-child(2n-1) {
  clear: left;
}

.section_content-head .section_tab a {
  position: relative;
  display: inline-block;
  font-family: "itc-avant-garde-gothic-pro", sans-serif;
  font-size: 38px;
  font-weight: 700;
  line-height: 1;
  border-bottom: 6px solid transparent;
}

.section_content-head .section_tab a:before {
  position: absolute;
  top: 100%;
  left: 0;
  width: 0;
  height: 6px;
  content: '';
  transition: width .25s;
}

.section_content-head .section_tab a:hover:before {
  width: 100%;
}

.section_content-head .section_tab a.on {
  color: #000;
}

.section_content-head .section_tab a.on:before {
  width: 100%;
  background: #000;
}

.section.badge .section_content-body {
  margin-top: 0;
}

.section.graph .mob {
  display: none;
}

.vm .section_content-head .title {
  font-size: 12px;
  font-weight: 300;
  width: 100%;
}

.vm .badge-summary li {
  width: 33%;
  display: inline-block;
  margin-bottom: 0px;
}

.vm .badge-summary li img {
  width: 75px;
  margin-left: 15px;
  margin-bottom: 20px;
}

.badge-summary li img {
  width: 160px;
  cursor: pointer;
}

.badge-summary li {
  width: 25%;
  display: inline-block;
  margin-bottom: 50px;
}

.badge-lst {
  margin-top: -20px;
}

.badge-lst:after, .badge-lst > ul:after {
  display: block;
  clear: both;
  content: '';
}

.badge-lst li {
  width: 25%;
  padding: 50px 0;
  display: inline-block;
  text-align: center;
}

.badge-lst [class*='badge'] {
  display: inline-block;
  width: 170px;
  height: 198px;
  text-indent: -999px;
  overflow: hidden;
}

.badge-lst .badge1 {
  background: url("../images/artist/badge1.jpg") no-repeat;
}

.badge-lst .badge2 {
  background: url("../images/artist/badge2.jpg") no-repeat;
}

.badge-lst .badge3 {
  background: url("../images/artist/badge3.jpg") no-repeat;
}

.badge-lst .badge4 {
  background: url("../images/artist/badge4.jpg") no-repeat;
}

.vm .badge-lst {
  margin: 0 -16px;
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
}

.vm .badge-lst:after {
  display: none;
}

.vm .badge-lst li {
  width: 33%;
  padding: 0 0px 50px;
}

.vm .badge-lst [class*=badge] {
  width: 110px;
  height: 128px;
  background-size: 100% auto;
}

@media screen and (max-width: 586px) {
  .vm .badge-lst {
    justify-content: end;
  }
}

@media screen and (max-width: 320px) {
  .vm .badge-lst {
    margin: 0;
  }
  .vm .badge-lst li {
    padding: 0 0 30px;
  }
}

.period_box {
  position: absolute;
  top: 45px;
  left: 0;
}

.period_box a {
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 48px;
  border-radius: 50%;
  border: 2px solid transparent;
  text-align: center;
  font-family: "itc-avant-garde-gothic-pro", sans-serif;
  font-size: 16px;
  box-sizing: border-box;
}

.period_box a + a {
  margin-left: 22px;
}

.period_box a:hover, .period_box a.on {
  border-color: #000;
  transition: border .3s;
}

.vm .period_box {
  position: static;
  text-align: right;
}

.vm .period_box a {
  width: 36px;
  height: 36px;
  line-height: 35px;
  border-width: 2px;
}

.vm .period_box a + a {
  margin-left: 19px;
}

.vm .artist {
  padding-top: 160px;
  margin: 0 auto;
  margin-bottom: 100px;
}

.vm .artist_header {
  top: 15px;
  right: 15px;
}

.vm .artist_header .area .en {
  font-size: 15px;
}

.vm .artist_header .area .kr {
  font-size: 9px;
}

.vm .artist_inner {
  margin-top: -70px;
}

.vm .artist_keyvisual {
  padding: 0 10.6666667%;
  margin-bottom: 145px;
}

.vm .artist_keyvisual .kr_name {
  left: 21.33333vw;
  font-size: 6.93333vw;
}

@media screen and (min-width: 750px) and (max-width: 1024px) {
  .vm .artist_keyvisual .kr_name {
    left: 160px;
    font-size: 52px;
  }
}

.vm .artist_keyvisual .en_name {
  bottom: 10.66667vw;
  font-size: 33.6vw;
}

@media (min-width: 750px) {
  .vm .artist_keyvisual .en_name {
    left: -32px;
    bottom: 80px;
    font-size: 252px;
  }
}

.v0.vm .section {
  padding: 0 20px;
}

.vm .section.graph .section_header {
  padding-top: 0px;
  white-space: nowrap;
}

.vm .section.chart {
  padding: 0;
}

.vm .section.chart .section_header,
.vm .section.chart .section_content-head {
  padding: 0 10.6666667%;
}

.vm .section.badge {
  width: 100%;
  margin-top: 60px;
  padding-bottom: 0px;
  margin-bottom: -30px;
}

.vm .section.badge .section_header {
  margin-top: -50px;
  margin-left: 10px;
  padding-top: 0px;
}

.vm .section.badge .section_header .sub_title {
  margin-top: 10px;
  padding-left: 0;
  font-size: 13px;
}

.vm .section .bg_copy {
  display: none;
}

.vm .section_header .h2_title {
  font-size: 38px;
}

.vm .section_content {
  margin-top: 45px;
}

.vm .section_content-head .section_tab {
  float: none;
  width: auto;
}

.vm .section_content-head .section_tab li {
  margin-bottom: 5px;
}

.vm .section_content-head .section_tab a {
  font-size: 20px;
}

.vm .section_content-head .section_tab a:before {
  height: 3px;
}

.vm .section_content-body {
  margin-top: 10px;
}

.vm .section .board_container {
  padding: 0;
  margin-bottom: 0;
}

.vm .section.graph .pc {
  display: none;
}

.vm .section.graph .mob {
  display: block;
}

.vm .badge-lst {
  padding: 0 0 55px;
}

.vm #footer .map_wrap,
.vm #footer .foot {
  padding-left: 15.46667vw;
  margin-left: 0;
}

.artist-list-wrap {
  position: relative;
  max-width: 1180px;
  margin: -128px auto 0;
  padding-bottom: 118px;
}

.vmgnb .artist-list-wrap {
  margin: -128px 70px 0;
}

.vm .artist-list-wrap {
  margin: -137px 0 0;
  padding-bottom: 73px;
}

.artist-list-wrap .search-wrap {
  position: fixed;
  bottom: 18px;
  max-width: 1180px;
  margin: 0 auto;
  z-index: 10;
}

.vm .artist-list-wrap .search-wrap {
  left: 0;
  bottom: 20px;
  width: 100%;
}

.vm .artist-list-wrap .search-wrap.open .btn-search {
  background-image: url("../images/artist/icon_search_w.svg");
}

.vm .artist-list-wrap .search-wrap.open .search-layer {
  transform: translateY(0);
}

.artist-list-wrap .search-wrap .btn-search {
  display: none;
}

.vm .artist-list-wrap .search-wrap .btn-search {
  position: absolute;
  bottom: 0;
  left: 20px;
  display: block;
  width: 20px;
  height: 20px;
  background-image: url("../images/artist/icon_search.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  border: 0;
  text-indent: -9999px;
  background-color: transparent;
  cursor: pointer;
  z-index: 1;
}

.vm.iphone .artist-list-wrap .search-wrap .btn-search {
  height: 21px;
}

.artist-list-wrap .search-wrap:before {
  position: absolute;
  top: 50%;
  left: 0;
  display: block;
  width: 40px;
  height: 40px;
  margin-top: -20px;
  background-image: url("../images/artist/icon_search.svg");
  background-size: 100%;
  opacity: 1;
  transition: all .3s;
  content: '';
}

.vm .artist-list-wrap .search-wrap:before {
  display: none;
}

.artist-list-wrap .search-wrap .search-layer {
  display: block;
}

.vm .artist-list-wrap .search-wrap .search-layer {
  position: relative;
  top: 20px;
  width: 100%;
  height: 180px;
  padding: 26px 85px 0;
  background-color: #000;
  box-sizing: border-box;
  transform: translateY(180px);
  transition: all .5s;
  display: none;
}

.artist-list-wrap .search-wrap .search-layer .tit {
  display: none;
}

.vm .artist-list-wrap .search-wrap .search-layer .tit {
  display: block;
  font-family: "itc-avant-garde-gothic-pro", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
}

.artist-list-wrap .search-wrap .search-layer input {
  width: 420px;
  height: 86px;
  padding-left: 59px;
  box-sizing: border-box;
  border: 0;
  font-family: "itc-avant-garde-gothic-pro", sans-serif;
  font-size: 64px;
  font-weight: 500;
  color: #000;
  line-height: 86px;
  transition: padding .3s;
  appearance: none;
}

.ie .artist-list-wrap .search-wrap .search-layer input {
  height: 100px;
  line-height: 100px;
}

.artist-list-wrap .search-wrap .search-layer input::-ms-clear {
  display: none;
}

.vm .artist-list-wrap .search-wrap .search-layer input {
  position: absolute;
  bottom: 10px;
  width: 260px;
  height: 40px;
  padding: 0;
  background-color: transparent;
  font-family: "itc-avant-garde-gothic-pro", sans-serif;
  font-size: 36px;
  font-weight: 500;
  color: #fff;
  line-height: 43px;
}

.iphone .artist-list-wrap .search-wrap .search-layer input {
  height: 43px;
  bottom: 8px;
}

.artist-list-wrap .search-wrap .search-layer .btn-close {
  display: none;
}

.vm .artist-list-wrap .search-wrap .search-layer .btn-close {
  position: absolute;
  top: 27px;
  right: 15px;
  display: block;
  width: 15px;
  height: 15px;
  background-color: #000;
  border: 0;
  text-indent: -99999px;
  z-index: 10;
}

.vm .artist-list-wrap .search-wrap .search-layer .btn-close:before, .vm .artist-list-wrap .search-wrap .search-layer .btn-close:after {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 22px;
  height: 4px;
  background-color: #fff;
  transform: rotate(45deg) translate(-50%, -50%);
  transform-origin: 0 0;
  content: '';
}

.vm .artist-list-wrap .search-wrap .search-layer .btn-close:after {
  transform: rotate(-45deg) translate(-50%, -50%);
}

.artist-list-wrap .search-wrap.absol {
  position: absolute !important;
}

.artist-list-wrap .search-wrap.focus:before {
  opacity: 0;
}

.artist-list-wrap .search-wrap.focus .search-layer input {
  padding-left: 0;
}

.artist-list-wrap .search-wrap ::placeholder {
  color: #000 !important;
}

.vm .artist-list-wrap .search-wrap ::placeholder {
  color: #fff !important;
}

.artist-list-wrap .scroll-mask {
  width: 50%;
  margin-left: 50%;
  overflow: hidden;
}

.vm .artist-list-wrap .scroll-mask {
  width: 70%;
  margin-left: 23%;
}

.artist-list-wrap .list-wrap {
  position: relative;
  width: 120%;
  height: 1370px;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.artist-list-wrap .list-wrap.min {
  height: 1045px;
}

.vm .artist-list-wrap .list-wrap {
  height: auto;
  overflow-y: auto;
}

.vm .artist-list-wrap .list-wrap.min {
  height: 610px;
}

.artist-list-wrap .list-wrap .list-scroll .category {
  display: block;
  margin-bottom: 23px;
}

.vm .artist-list-wrap .list-wrap .list-scroll .category {
  margin-bottom: 15px;
  margin-right: 100px;
}

.artist-list-wrap .list-wrap .list-scroll .category a {
  top: 14px;
  left: 5px;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 68px;
  font-weight: bold;
}

.vm .artist-list-wrap .list-wrap .list-scroll .category a {
  top: 6px;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 38px;
  font-weight: bold;
}

.artist-list-wrap .list-wrap .list-scroll .category a:hover {
  -webkit-text-stroke: 1px #606167;
  -webkit-text-fill-color: transparent;
}

.artist-list-wrap .list-wrap .list-scroll .category a:first-child {
  margin: 0;
}

.ie .artist-list-wrap .list-wrap .list-scroll .category a:hover {
  color: #fff;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

.artist-list-wrap .list-wrap .list-scroll .category a.en {
  font-family: "itc-avant-garde-gothic-pro", sans-serif;
  font-size: 72px;
  font-weight: 500;
}

.vm .artist-list-wrap .list-wrap .list-scroll .category a.en {
  font-family: "itc-avant-garde-gothic-pro", sans-serif;
  font-size: 40px;
  font-weight: 500;
}

.artist-list-wrap .list-nav {
  position: absolute;
  padding-left: 20px;
  top: 0px;
  right: 0;
  display: inline-block;
  text-align: center;
  background-color: #fff;
}

.vm .artist-list-wrap .list-nav {
  right: 12px;
}

.vm .artist-list-wrap .list-nav.absol {
  position: absolute;
  top: auto;
  bottom: 20px;
}

.vm .artist-list-wrap .list-nav.fixed {
  position: fixed;
  top: auto;
  bottom: 20px;
}

.artist-list-wrap .list-nav a {
  position: relative;
  display: block;
  margin-top: 10px;
}

.vm .artist-list-wrap .list-nav a {
  margin-top: 0px;
}

.artist-list-wrap .list-nav a:first-child {
  margin: 0;
}

.artist-list-wrap .list-nav a span {
  position: relative;
  left: 0;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #000;
  transition: all .3s ease-in-out;
}

.vm .artist-list-wrap .list-nav a span {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 12px;
  font-weight: bold;
}

.artist-list-wrap .list-nav a.en span {
  font-family: "itc-avant-garde-gothic-pro", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.vm .artist-list-wrap .list-nav a.en span {
  font-family: "itc-avant-garde-gothic-pro", sans-serif;
  font-size: 10px;
  font-weight: 500;
}

.artist-list-wrap .list-nav a:before {
  position: absolute;
  right: 0;
  bottom: -6px;
  width: 0;
  height: 1px;
  background-color: #000;
  content: '';
  transition: all .3s ease-in-out;
}

.vm .artist-list-wrap .list-nav a:before {
  bottom: -3px;
}

.artist-list-wrap .list-nav a.active span, .artist-list-wrap .list-nav a:hover span {
  left: -38px;
}

.vm .artist-list-wrap .list-nav a.active span, .vm .artist-list-wrap .list-nav a:hover span {
  left: -19px;
}

.artist-list-wrap .list-nav a.active:before, .artist-list-wrap .list-nav a:hover:before {
  width: 52px;
}

.vm .artist-list-wrap .list-nav a.active:before, .vm .artist-list-wrap .list-nav a:hover:before {
  width: 26px;
}

.section_tab_2 {
  max-width: 980px;
  margin-left: 120px;
  padding: 70px 0 70px;
}

.section_tab_2:after {
  display: block;
  clear: both;
  content: '';
}

.section_tab_2 li {
  position: relative;
  font-size: 23px;
  line-height: 33px;
  float: left;
  vertical-align: top;
}

.section_tab_2 li + li {
  margin-left: 16%;
}

.section_tab_2 li > a {
  position: relative;
  display: block;
  min-width: 150px;
  padding-right: 10px;
  box-sizing: border-box;
}

.section_tab_2 li > a:after {
  content: '';
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  right: 0;
  margin-top: -3px;
  border-top: 6px solid #000;
  border-right: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid transparent;
}

.section_tab_2 li > a span {
  display: inline-block;
  position: relative;
}

.section_tab_2 li > a span:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 4px;
  display: block;
  height: 1px;
  width: 100%;
  background: #000;
}

.section_tab_2 li.open > a:after {
  margin-top: -10px;
  transform: rotate(180deg);
}

.section_tab_2 .dropdown_box {
  display: none;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 75px;
  width: 200px;
  background: #fff;
  border: 1px solid #000;
}

.section_tab_2 .dropdown_box a {
  display: block;
  width: 100%;
  height: 46px;
  line-height: 46px;
  font-size: 16px;
  padding-left: 20px;
  box-sizing: border-box;
}

.section_tab_2 .dropdown_box a + a {
  border-top: 1px solid #000;
}

.section_tab_2 .dropdown_box a.on, .section_tab_2 .dropdown_box a:hover, .section_tab_2 .dropdown_box a:focus {
  background: #000;
  color: #fff;
}

.vm .section_tab_2 .dropdown_box {
  width: 100px;
  top: 34px;
}

.vm .section_tab_2 .dropdown_box a {
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  padding-left: 10px;
}

.section_tab_2 li.gender > a span {
  color: transparent;
  white-space: nowrap;
}

.section_tab_2 li.gender > a span:after {
  display: none;
}

.section_tab_2 li.gender i {
  display: inline-block;
  width: 26px;
  height: 26px;
  margin-right: 12px;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-size: 100% auto;
}

.section_tab_2 li.gender i.all {
  background-image: url(../images/board/icon_emoticon_all.svg);
}

.section_tab_2 li.gender i.woman {
  background-image: url(../images/board/icon_emoticon_woman.svg);
}

.section_tab_2 li.gender i.man {
  background-image: url(../images/board/icon_emoticon_man.svg);
}

.section_tab_2 li.gender .dropdown_box a.on i.all,
.section_tab_2 li.gender .dropdown_box a:hover i.all,
.section_tab_2 li.gender .dropdown_box a:focus i.all {
  background-image: url(../images/board/icon_emoticon_all_w.svg);
}

.section_tab_2 li.gender .dropdown_box a.on i.woman,
.section_tab_2 li.gender .dropdown_box a:hover i.woman,
.section_tab_2 li.gender .dropdown_box a:focus i.woman {
  background-image: url(../images/board/icon_emoticon_woman_w.svg);
}

.section_tab_2 li.gender .dropdown_box a.on i.man,
.section_tab_2 li.gender .dropdown_box a:hover i.man,
.section_tab_2 li.gender .dropdown_box a:focus i.man {
  background-image: url(../images/board/icon_emoticon_man_w.svg);
}

.vm .section_tab_2 {
  padding: 0;
  padding-top: 36px;
  margin-left: 0px !important;
}

.vm .section_tab_2 ul li {
  font-size: 17px;
  line-height: 25px;
}

.vm .section_tab_2 ul li + li {
  margin-left: 10%;
}

.vm .section_tab_2 ul li > span {
  display: none;
}

.vm .section_tab_2 ul li > a {
  min-width: 72px;
  background-size: 7px 4px;
}

.vm .section_tab_2 ul li > a span:after {
  bottom: 2px;
  height: 2px;
}

.vm .section_tab_2 ul li > a:after {
  border-width: 4px;
  margin-top: -2px;
}

.vm .section_tab_2 ul li.gender > a {
  width: 72px;
}

.vm .section_tab_2 ul li.open > a:after {
  margin-top: -7px;
}

.vm .section_tab_2 .gender i {
  width: 16px;
  height: 16px;
}

.modal ul.sns-lst {
  padding: 0 10vw 2.13333vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.modal ul.sns-lst li {
  width: 50%;
  box-sizing: border-box;
  padding: 4.26667vw;
}

.modal ul.sns-lst a {
  display: block;
}

.modal ul.sns-lst img {
  display: block;
  width: 100%;
  max-width: 55px;
  margin: 0 auto;
}

.modal .url_area {
  position: relative;
  height: 10.66667vw;
  padding-left: 4.53333vw;
  margin-top: 2.66667vw;
}

.modal .url_area .input {
  display: block;
  padding-left: 8vw;
  bottom: 3px;
  position: inherit;
}

.modal .url_area input[type="text"] {
  width: 100%;
  font-family: "itc-avant-garde-gothic-pro", sans-serif;
  font-size: 3.2vw;
  font-weight: 300;
  line-height: 10.66667vw;
  height: 10.66667vw;
  text-overflow: ellipsis;
  border: 0;
  background: transparent;
}

.modal .url_area .url {
  position: absolute;
  left: 8px;
  bottom: 5px;
  width: 9.66667vw;
  height: 9.66667vw;
  background: url(../images/icon_brief_url.jpg) 50% 50% no-repeat;
  background-size: 50% auto;
  border: 0;
}

.modal__overlay {
  padding: 0 14vw;
}

.modal__header {
  height: 10.66667vw;
  display: block;
  text-align: right;
}

.modal__content {
  margin-top: 0;
  margin-bottom: 0;
  margin: 0 !important;
  overflow-y: hidden !important;
  color: inherit;
}

.modal__container {
  padding: 0 !important;
  border-radius: 0 !important;
  max-width: 700px !important;
}

.sns-share.modal__container {
  overflow: hidden;
}

.modal__close {
  margin: 7px;
  position: relative;
  width: 10.66667vw;
  height: 10.66667vw;
  text-indent: -999px;
}

.modal__close:before {
  position: absolute;
  width: 4vw;
  height: 4vw;
  background: url("../images/icon_brief_close.jpg") no-repeat 50% 50%;
  background-size: 100%;
  top: 50%;
  left: 50%;
  content: '';
  font-size: 0;
  transform: translate(-50%, -50%);
}

.modal__header {
  display: block !important;
}

.artist .sns-lst {
  position: absolute;
  width: 30px;
  left: 1088.5px;
  top: 300px;
  opacity: 0;
  display: none;
  right: 55px;
  width: 198px;
  height: 30px;
  font-size: 0;
}

.artist .sns-lst a, .artist .sns-lst i {
  display: inline-block;
  width: 30px;
  height: 30px;
  font-size: 1rem;
}

.artist .sns-lst a + a {
  margin-left: 12px;
}

.artist .sns-lst i {
  opacity: 0;
}

.artist .sns-lst i.tw {
  background: url("../images/brief/ico_brief_share_twitter.svg") 50% 50% no-repeat;
  background-size: 24px auto;
}

.artist .sns-lst i.fb {
  background: url("../images/brief/ico_brief_share_facebook.svg") 50% 50% no-repeat;
  background-size: 24px auto;
}

.artist .sns-lst i.kakao {
  background: url("../images/brief/ico_brief_share_kakao.svg") 50% 50% no-repeat;
  background-size: 24px auto;
}

.artist .sns-lst i.line {
  background: url("../images/brief/ico_brief_share_line.svg") 50% 50% no-repeat;
  background-size: 24px auto;
}

.artist .sns-lst i.url {
  background: url("../images/brief/ico_brief_share_link.svg") 50% 50% no-repeat;
  background-size: 24px auto;
}

.artist .sns-lst {
  opacity: 1;
  display: block;
}

.artist .sns-lst i {
  transform: none;
  opacity: 1;
  transition: transform .4s, opacity 1s;
}

.vm .artist-list-wrap .search-wrap.open {
  position: absolute;
  height: 180px;
}

.vm .artist-list-wrap .search-wrap {
  position: fixed;
  height: 0px;
}

.tab_info {
  background-image: url("/@resources/images/artist/kr-chartdetail-btn-info.svg");
  display: inline-block;
  width: 20px;
  height: 22px;
  margin-right: 8px;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-size: 100% auto;
}

.vm .tab_info {
  width: 12px;
  height: 14px;
  margin-right: 4px;
}

.vm .chart .section_content {
  margin-top: 10px;
}

.vm .graph .section_content {
  margin-top: 10px;
}

.vm .chart .section_content-body {
  margin-top: 8px;
}

.tab_info {
  background-image: url("/@resources/images/artist/kr-chartdetail-btn-info2.svg");
  display: inline-block;
  width: 20px;
  height: 22px;
  margin-left: 2px;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-size: 100% auto;
  cursor: pointer;
}

.vm .tab_info {
  width: 12px;
  height: 14px;
  margin-right: 4px;
}

#artistImg {
  display: inline-block;
  position: relative;
  z-index: -1;
  /* object-fit: contain; */
  /* height: 400px; */
  /* position: relative; */
}

.vm #enName {
  font-size: 89px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.9;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  top: 360px;
  left: 30px;
}

#enName {
  font-size: 254px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.94;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  top: 400px;
  left: 50px;
}

.vm #krName {
  font-size: 30px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.91;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  top: 300px;
  left: 30px;
}

#krName {
  font-size: 50px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  top: 250px;
  left: 32px;
}

#snsChart {
  top: 20px;
  position: relative;
}

#snsChart svg {
  background: white;
}

.header_minify .ko .section_tab_2 a span {
  font-size: 21px;
}

.vm .ko .section_tab_2 a span {
  font-size: 17px;
}

.ko .section_tab_2 a span {
  font-size: 21px;
  font-weight: bold !important;
  font-family: "Noto Sans KR", sans-serif;
}

.ko .section_tab_2 .dropdown_box a span {
  font-size: 16px !important;
  font-weight: bold !important;
  font-family: "Noto Sans KR", sans-serif;
}

.header_minify .ko .section_tab_2 a span {
  font-size: 21px;
  font-weight: bold !important;
  font-family: "Noto Sans KR", sans-serif;
}

.header_minify .ko .section_tab_2 .dropdown_box a span {
  font-size: 16px !important;
  font-weight: bold !important;
  font-family: "Noto Sans KR", sans-serif;
}

.vm .ko .section_tab_2 a span {
  font-size: 17px;
  font-weight: bold !important;
  font-family: "Noto Sans KR", sans-serif;
}

.vm.header_minify .ko .board_header .section_tab_2 a span {
  font-size: 13px;
  font-weight: bold !important;
  font-family: "Noto Sans KR", sans-serif;
}

.vm .ko .section_tab_2 .dropdown_box a span {
  font-size: 14px !important;
  font-weight: bold !important;
  font-family: "Noto Sans KR", sans-serif;
}

.vm.header_minify .ko .section_tab_2 .dropdown_box a span {
  font-size: 10px !important;
  font-weight: bold !important;
  font-family: "Noto Sans KR", sans-serif;
}

.vm .badge-summary {
  padding-top: 30px;
}

.badge-summary {
  padding-top: 80px;
}

.badge2 span {
  position: relative;
  right: 31px;
  bottom: 179px;
  color: #fff;
  font-size: 17px;
  font-weight: bold;
}

.vm .badge2 div {
  min-width: 110px;
}

.vm .badge2 span {
  right: 15px;
  bottom: 103px;
  font-size: 9px;
  font-weight: bold;
}

/* .vm .badge2{
  padding-bottom:500px;		
} */
.badge_title {
  font-size: 30px !important;
}

.vm .badge_title {
  font-size: 15px !important;
}

text {
  font-family: 'itc-avant-garde-gothic-pro',sans-serif;
}

.flip-card {
  background-color: transparent;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flip-card-inner.active {
  transform: rotateY(360deg);
}

#badgeList img {
  width: 160px;
  cursor: pointer;
}

.vm #badgeList img {
  width: 75px;
}

.vm #badgeList div {
  margin-right: 15px;
}

/* .total span{
   font-size:15px; 
} */
.inc span {
  font-size: 12px;
}

#badgeList span.bad_title {
  position: relative;
  top: 20px;
  font-size: 17px;
}

#badgeList span.bad_date {
  position: relative;
  top: 25px;
  font-size: 14px;
  color: #999999;
  font-weight: 100;
}

.vm #badgeList span.bad_title {
  position: relative;
  top: 5px;
  font-size: 10px;
}

.vm #badgeList span.bad_date {
  position: relative;
  top: 8px;
  font-size: 10px;
  color: #999999;
  font-weight: 100;
}

#r_artists {
  padding-top: 40px;
  padding-bottom: 30px;
}

.vm #r_artists {
  padding-top: 20px;
  padding-bottom: 0px;
}

#r_artists li {
  width: 25%;
  display: inline-block;
}

#r_artists li div {
  width: 175px;
  height: 175px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
}

#r_artists li div .arrow {
  padding-left: 5px;
  width: 6px;
}

.vm #r_artists li div .arrow {
  width: 4px;
}

#r_artists li div span {
  position: absolute;
  color: #fff;
  top: 50%;
  left: 50%;
  text-align: center;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  transform: translate(-50%, -50%);
  text-decoration: underline;
}

.vm #r_artists li div span {
  font-size: 13px;
}

#r_artists li div .artist_img {
  width: 175px;
  height: 175px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  object-fit: cover;
}

#r_artists li div .background_ {
  width: 175px;
  height: 175px;
  position: absolute;
  opacity: .4;
  bottom: 0px;
  border-radius: 50%;
  background: #000;
}

.vm #r_artists li div {
  width: 80px;
  height: 80px;
}

.vm #r_artists li div .background_ {
  width: 80px;
  height: 80px;
}

.vm #r_artists li div .artist_img {
  width: 80px;
  height: 80px;
}

.viewcount_more {
  margin-top: 30px;
  font-size: 15px;
  text-align: center;
  cursor: pointer;
  font-weight: bold;
}

.vm .viewcount_more {
  font-size: 13px;
  margin-top: 20px;
}

.viewcount_more img {
  width: 12px;
}

.vm .viewcount_more img {
  width: 10px;
}

.today {
  padding-top: 120px;
}

.today .title {
  display: inline-block;
  font-family: 'itc-avant-garde-gothic-pro',sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 1;
  border-bottom: 6px solid transparent;
}

.vm .today {
  padding-top: 40px;
}

.vm .today .title {
  font-size: 20px;
}

.today .order {
  position: relative;
  top: 33px;
  left: 205px;
  color: #999999;
}

.vm .today .order {
  top: 9px;
  left: 110px;
  font-size: 10px;
}

.today .orderDiff {
  position: relative;
  top: 40px;
  left: 220px;
}

.vm .today .orderDiff {
  top: 12px;
  left: 120px;
  font-size: 8px;
}

.today .total {
  font-family: 'itc-avant-garde-gothic-pro', sans-serif;
  font-size: 36px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.67;
  letter-spacing: normal;
  text-align: left;
  color: #999999;
  position: absolute;
  margin: 20px 10px 15px 0px;
}

.vm .today .total {
  font-size: 18px;
  margin: 10px 10px 0 0;
}

.today .incOrder {
  position: relative;
  top: 68px;
  left: 210px;
}

.vm .today .incOrder {
  top: 25px;
  left: 115px;
  font-size: 10px;
}

.today .incOrderDiff {
  position: relative;
  top: 68px;
  left: 220px;
}

.vm .today .incOrderDiff {
  top: 25px;
  left: 120px;
  font-size: 10px;
}

.today .inc {
  font-family: 'itc-avant-garde-gothic-pro',sans-serif;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.68;
  letter-spacing: normal;
  text-align: left;
  position: absolute;
  margin: 40px 10px 20px 0px;
}

.vm .today .inc {
  font-size: 10px;
  margin: 15px 10px 20px 0;
}

.today .inc.up {
  color: #ff354e;
}

.today .inc.down {
  color: #00a2ff;
}

.today ul li {
  display: inline-block;
  width: 49%;
  padding-bottom: 150px;
}

.vm .today ul li {
  display: inline-block;
  width: 100%;
  padding-bottom: 65px;
}

.today_tab .up i {
  background-image: url(/@resources/images/board/icon_rank_up.svg);
  display: inline-block;
  width: 9px;
  height: 8px;
  color: transparent;
  vertical-align: middle;
  margin-right: 8px;
  background-repeat: no-repeat;
  background-position: 0 0;
}

.today_tab .down i {
  background-image: url(/@resources/images/board/icon_rank_down.svg);
  display: inline-block;
  width: 9px;
  height: 8px;
  color: transparent;
  vertical-align: middle;
  margin-right: 7px;
  background-repeat: no-repeat;
  background-position: 0 0;
}

.vm .today_tab .up i {
  width: 8px;
  height: 7px;
}

.vm .today_tab .down i {
  width: 8px;
  height: 7px;
}

.vm .today_tab {
  padding-top: 0px;
  padding-bottom: 40px;
}

.today_tab {
  padding-bottom: 100px;
}

.macos #today_date {
  position: relative;
  left: 8px;
  color: #999999;
}

#today_date {
  position: relative;
  left: 8px;
  color: #999999;
}

.vm #today_date {
  /* top: -18px; */
  left: 0px;
  color: #999;
}

.vm .today_tab .link img {
  width: 5px;
  position: relative;
  top: -1px;
  left: 6px;
}

.today_tab .link img {
  width: 7px;
  top: -4px;
  position: relative;
  left: 15px;
}

.section_tab.today_tab .title2 {
  font-weight: 300;
  float: left;
  width: 100%;
  font-family: "Noto Sans KR",sans-serif;
  font-size: 16px;
}

.vm .section_tab.today_tab .title2 {
  font-size: 12px;
}

.today_tab .section_content-head {
  padding-bottom: 80px;
  padding-top: 20px;
}

.vm .today_tab .section_content-head {
  padding-top: 10px;
  padding-bottom: 40px;
}

.r_artist_title {
  font-size: 24px !important;
  font-weight: 500;
}

.vm .r_artist_title {
  font-size: 13px !important;
}

.section.recommand {
  margin-top: 40px;
}

.vm .section.recommand {
  margin-top: 45px;
}

.badge2 .badge_title {
  cursor: pointer;
}

.badge_arrow {
  left: 18px;
  position: relative;
  width: 10px;
  top: -2px;
  cursor: pointer;
}

.vm .badge_arrow {
  left: 8px;
  position: relative;
  width: 5px;
  top: -1px;
}

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

.pop_up {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  -ms-transform: scale(1) rotate(0) translate(0, 0) skew(0, 0);
  transform: scale(1) rotate(0) translate(0, 0) skew(0, 0);
  transition-property: transform, opacity;
  transition-duration: .3s;
}

.popup_hide {
  opacity: 0;
  -ms-transform: scale(0.5) rotate(0) translate(0, -200%) skew(0, 0);
  transform: scale(0.5) rotate(0) translate(0, -200%) skew(0, 0);
  /*if you want to change popup view init show way*/
}

.pop_up_close {
  position: absolute;
  top: 40px;
  right: 100px;
  margin: 20px 20px 0 0;
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.vm .pop_up_close {
  top: 50px;
  right: 50px;
  width: 20px;
  height: 20px;
}

.pop_up_close:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 90%;
  height: 3px;
  background: #000;
  -ms-transform: rotate(45deg) translate(10px, 10px);
  transform: rotate(45deg) translate(10px, 10px);
}

.pop_up_close:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 90%;
  height: 3px;
  background: #000;
  -ms-transform: rotate(-45deg) translate(-10px, 10px);
  transform: rotate(-45deg) translate(-10px, 10px);
}

.hex_badge {
  overflow: hidden;
  display: inline-block;
  margin-top: 130px;
}

.vm .hex_badge {
  margin-top: 200px;
}

.hex_badge img {
  width: 160px;
}

.hex_div {
  margin-top: -80px;
}

.ie .hex_div {
  margin-top: -60px;
}

.hexes .hex_div:nth-child(2) {
  margin-top: 15px;
}

.vm .hex_div {
  margin-top: -120px;
}

.vm .hexes .hex_div:nth-child(2) {
  margin-top: -120px;
}

.r-hex {
  overflow: hidden;
  display: inline-block;
  margin-right: -7px;
  margin-left: -7px;
  width: 14em;
  height: 12.32em;
  -webkit-transform: rotate(-30deg) skewX(30deg);
  -ms-transform: rotate(-30deg) skewX(30deg);
  transform: rotate(-30deg) skewX(30deg);
  border-radius: 0.7em;
}

.vm .r-hex {
  width: 110px;
  margin-right: 0px;
  margin-left: 0px;
}

.r-hex *, .r-hex *:before {
  display: block;
  overflow: hidden;
  width: inherit;
  height: inherit;
  border-radius: inherit;
}

.r-hex-inner {
  transform: skewX(-30deg) rotate(60deg) skewX(30deg);
  transition: opacity .75s;
  cursor: pointer;
}

.r-hex-inner-2 {
  transform: skewX(-30deg) rotate(60deg) skewX(30deg);
  background: #000;
  content: '';
}

.r-hex-inner-2:before {
  margin-top: -1.5em;
  height: 15em;
  -webkit-transform: skewX(-30deg) rotate(-90deg);
  -ms-transform: skewX(-30deg) rotate(-90deg);
  transform: skewX(-30deg) rotate(-90deg);
  background-size: 250%;
  line-height: 15em;
  text-align: center;
  opacity: 0.7;
  background-repeat: no-repeat;
  background-position: 50%;
  content: "";
}

.badge_name {
  white-space: nowrap;
  position: relative;
  color: #fff;
  font-family: notosans;
  bottom: 99px;
  text-overflow: ellipsis;
  width: 150px;
  left: 18px;
  text-align: center;
  overflow: hidden;
  font-weight: bold;
}

.vm .badge_name {
  max-width: 90px;
  text-align: center;
  overflow: hidden;
  font-size: 11px;
  left: 10px;
  bottom: 97px;
}

.badge_date {
  position: relative;
  color: #fff;
  font-size: 12px;
  font-weight: 100;
  bottom: 92px;
}

.vm .badge_date {
  bottom: 94px;
  font-size: 10px;
}

.badge_play {
  position: relative;
  width: 30px;
  bottom: 110px;
  left: 4px;
  cursor: pointer;
}

.vm .badge_play {
  width: 13px;
  bottom: 100px;
  left: 0px;
}

.hasNotice .artist {
  padding-top: 340px;
}

.vm.hasNotice .artist {
  padding-top: 190px;
}

.artist .heart-lst {
  position: absolute;
}

#artist_count {
  position: relative;
  bottom: 36px;
  left: 44px;
  user-select: none;
}

.vm #artist_count {
  font-size: 12px;
}

#heart_clicker {
  width: 50px;
  height: 50px;
}
